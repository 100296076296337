import * as actionType from "./adminActionType";

const initialState = {
    isLoading: false,
    isSaved:false,
    isLoggedIn: sessionStorage.getItem('loggedOwner') !== null ? true : false,
    loggedOwner: sessionStorage.getItem('loggedOwner') !== null ? JSON.parse(sessionStorage.getItem('loggedOwner')) : {}
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.OWNER_LOGIN:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.OWNER_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.OWNER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedOwner: action.payload ? action.payload.data : {},
            };
        case actionType.OWNER_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.OWNER_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.OWNER_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        case actionType.GET_CURRENT_OWNER:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.GET_CURRENT_OWNER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.GET_CURRENT_OWNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedOwner: action.payload ? action.payload.data : {},
            };
        default:
            return state;
    }
}