import React, { lazy, Suspense } from "react";
import { ProtectedRoute } from './app/protected.route';
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";

const LoginContainer = lazy(() => import('./view/login/login.container'));
const DashboardContainer = lazy(() => import('./view/dashboard/dashboard.container'));
const ForgotPasswordContainer = lazy(() => import('./view/forgot-password/forgot-password.container'));
const PropertiesAddContainer = lazy(() => import('./view/properties-add/properties-add.container'));
const CustomersContainer = lazy(()=> import('./view/customers/customers.container'));
const BookingPropertyTableContainer = lazy(()=> import('./view/booking-table/bookingTable.container'));
const BookingCalendarContainer = lazy(()=> import('./view/booking-calendar/booking-calendar.container'));
const PropertyContainer = lazy(()=> import('./view/property/property.container'));
const SettingContainer = lazy(()=> import('./view/setting/setting.container'));
const RoutesPath = () => {
    return (
        <Box>
            <Routes>
                <Route path="/login" element={<Suspense fallback={<>...</>}><LoginContainer /></Suspense>} />
                <Route path="/" element={<Suspense fallback={<>...</>}><ProtectedRoute><DashboardContainer /></ProtectedRoute></Suspense>} />
                <Route path="/forgot-password" element={<Suspense fallback={<>...</>}><ProtectedRoute><ForgotPasswordContainer /></ProtectedRoute></Suspense>} />
                <Route path="/property" element={<Suspense fallback={<>...</>}><ProtectedRoute><PropertyContainer /></ProtectedRoute></Suspense>} />
                <Route path="/property/create" element={<Suspense fallback={<>...</>}><ProtectedRoute></ProtectedRoute><PropertiesAddContainer /></Suspense>} />
                <Route path="/customers" element={<Suspense fallback={<>...</>}><ProtectedRoute><CustomersContainer /></ProtectedRoute></Suspense>} />
                <Route path="/booking-table" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingPropertyTableContainer /></ProtectedRoute></Suspense>} />
                <Route path="/booking-calendar" element={<Suspense fallback={<>...</>}><ProtectedRoute><BookingCalendarContainer /></ProtectedRoute></Suspense>} />
                <Route path="/setting" element={<Suspense fallback={<>...</>}><ProtectedRoute><SettingContainer /></ProtectedRoute></Suspense>} />
            </Routes>
        </Box>
    )
}

export default RoutesPath;