import Approot from "./app-root-component";
import { connect } from "react-redux";

const mapDispatchToProps = {};

const mapStateToProps = ( ) => ({
   
});

export default connect(mapStateToProps, {})(Approot);
