import *  as ActionTypes from "./propertyActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    properties: [],
    images:[],
    property:[],
};

export const propertyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_PROPERTY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_PROPERTY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_PROPERTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_PROPERTY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_PROPERTY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_PROPERTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_PROPERTY_IMAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_PROPERTY_IMAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_PROPERTY_IMAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_PROPERTY:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PROPERTY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PROPERTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                properties: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_PROPERTY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_PROPERTY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_PROPERTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.CREATE_PROPERTY_IMAGE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_PROPERTY_IMAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_PROPERTY_IMAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
                images:   action?.payload?.data || [],
            }
        case ActionTypes.GET_PROPERTY_BY_ID:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PROPERTY_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PROPERTY_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                property: action?.payload?.data || []
            }
        case ActionTypes.CREATE_ROOM:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_ROOM_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.UPDATE_ROOM:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_ROOM_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_ROOM:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_ROOM_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_ROOM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}