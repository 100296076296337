import *  as ActionTypes from "./amenitiesActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    amenities: []
};

export const amenitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_AMENITIES:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_AMENITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                amenities: action?.payload?.data || []
            }
        default:
            return state

    }
}