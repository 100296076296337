import *  as ActionTypes from "./listingTypeActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    listingTypes: []
};

export const listingTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_LISTING_TYPE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_LISTING_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_LISTING_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listingTypes: action?.payload?.data || []
            }
        default:
            return state

    }
}