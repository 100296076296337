import *  as ActionTypes from "./cityActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    cities: []
};

export const cityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_CITY:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CITY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_CITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cities: action?.payload?.data || []
            }
        default:
            return state

    }
}