export const CREATE_PROPERTY = "CREATE_PROPERTY";
export const CREATE_PROPERTY_FAILURE = "CREATE_PROPERTY_FAILURE";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";

export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const DELETE_PROPERTY_FAILURE = "DELETE_PROPERTY_FAILURE";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";

export const GET_PROPERTY = "GET_PROPERTY";
export const GET_PROPERTY_FAILURE = "GET_PROPERTY_FAILURE";
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS";

export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPDATE_PROPERTY_FAILURE = "UPDATE_PROPERTY_FAILURE";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";

export const CREATE_PROPERTY_IMAGE = "CREATE_PROPERTY_IMAGE";
export const CREATE_PROPERTY_IMAGE_FAILURE = "CREATE_PROPERTY_IMAGE_FAILURE";
export const CREATE_PROPERTY_IMAGE_SUCCESS = "CREATE_PROPERTY_IMAGE_SUCCESS";

export const GET_PROPERTY_BY_ID = "GET_PROPERTY_BY_ID";
export const GET_PROPERTY_BY_ID_FAILURE = "GET_PROPERTY_BY_ID_FAILURE";
export const GET_PROPERTY_BY_ID_SUCCESS = "GET_PROPERTY_BY_ID_SUCCESS";

export const DELETE_PROPERTY_IMAGE = "DELETE_PROPERTY_IMAGE";
export const DELETE_PROPERTY_IMAGE_FAILURE = "DELETE_PROPERTY_IMAGE_FAILURE";
export const DELETE_PROPERTY_IMAGE_SUCCESS = "DELETE_PROPERTY_IMAGE_SUCCESS";

export const CREATE_ROOM = "CREATE_ROOM";
export const CREATE_ROOM_FAILURE = "CREATE_ROOM_FAILURE";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";

export const UPDATE_ROOM = "UPDATE_ROOM";
export const UPDATE_ROOM_FAILURE = "UPDATE_ROOM_FAILURE";
export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS";

export const DELETE_ROOM = "DELETE_ROOM";
export const DELETE_ROOM_FAILURE = "DELETE_ROOM_FAILURE";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";