import *  as ActionTypes from "./stateActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    states: []
};

export const stateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_STATE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_STATE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_STATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                states: action?.payload?.data || []
            }
        default:
            return state

    }
}