import * as actionType from "./bookingActionType";

const initialState = {
  isLoading: false,
  booking: null,
  bookingConfirm: false,
  bookings: [],
  calendarBookings: []
};

export const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookings: action.payload ? action.payload.data : false
            };
        case actionType.FETCH_CALENDAR_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_CALENDAR_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_CALENDAR_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                calendarBookings: action.payload ? action.payload.data : []
            };
        default:
            return state;
    }
}