import { combineReducers } from "redux";
import { adminReducer as adminPage } from "./../api/admin/adminReducer";
import { bookingStatusReducer as bookingStatusPage } from "./../api/bookingStatus/bookingStatusReducer";
import { countryReducer as countryPage } from "../api/country/countryReducer";
import { stateReducer as statePage } from "../api/state/stateReducer";
import { amenitiesReducer  as amenitiesPage } from "../api/amenities/amenitiesReducer";
import { listingTypeReducer  as listingTypePage } from "../api/listing-type/listingTypeReducer";
import { propertyTypeReducer as propertyTypePage } from "../api/property-type/propertyTypeReducer";
import { roomTypeReducer as roomTypePage } from "../api/room-type/roomTypeReducer";
import { cityReducer as cityPage } from "../api/city/cityReducer";
import { propertyReducer  as propertyPage } from "../api/property/propertyReducer";
import { customerReducer as customerPage } from "../api/customers/customersReducer";
import { tagReducer as tagPage } from "../api/tags/tagsReducer";
import { bookingReducer as bookingPage } from "../api/booking/bookingReducer";



const appReducer = combineReducers({
    adminPage,
    bookingStatusPage,
    countryPage,
    statePage,
    amenitiesPage,
    listingTypePage,
    propertyTypePage,
    roomTypePage,
    cityPage,
    propertyPage,
    customerPage,
    tagPage,
    bookingPage
})

export default appReducer;