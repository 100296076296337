export const OWNER_LOGIN = "OWNER_LOGIN";
export const OWNER_LOGIN_FAILURE = "OWNER_LOGIN_FAILURE";
export const OWNER_LOGIN_SUCCESS = "OWNER_LOGIN_SUCCESS";

export const OWNER_UPDATE = "OWNER_UPDATE";
export const OWNER_UPDATE_FAILURE = "OWNER_UPDATE_FAILURE";
export const OWNER_UPDATE_SUCCESS = "OWNER_UPDATE_SUCCESS";
 
export const GET_CURRENT_OWNER = "GET_CURRENT_OWNER";
export const GET_CURRENT_OWNER_FAILURE = "GET_CURRENT_OWNER_FAILURE";
export const GET_CURRENT_OWNER_SUCCESS = "GET_CURRENT_OWNER_SUCCESS";